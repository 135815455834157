import { Container } from "@mui/material";
import { Text } from "@mantine/core";

import MissionIcon from "../../../Assets/IMG/Home/MissionIcon.png";
import VisionIcon from "../../../Assets/IMG/Home/VisionIcon.png";

import "./styles.scss";
export default function VisionMission() {
  return (
    <div className="vision-mission-container">
      <Container maxWidth="md">
        <div className="row flex-row justify-between">
          <div className="card flex-col" data-aos="fade-up">
            <img src={VisionIcon} alt="" className="image" />
            <Text className="header">Our Vision</Text>
            <Text className="content">
              To be the leading aquaculture tech company in Africa, providing
              innovative solutions for sustainable, efficient and secure fish
              farming
            </Text>
          </div>
          <div className="card flex-col" data-aos="fade-down">
            <img src={MissionIcon} alt="" className="image" />
            <Text className="header">Our Mission</Text>
            <Text className="content">
              To drive adoption of automation technology in aquaculture
              practices by small and medium scale farmers and facilitate
              knowledge transfer amongst our community of farmers
            </Text>
          </div>
        </div>
      </Container>
    </div>
  );
}
