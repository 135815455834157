import { Link } from "react-router-dom";

import { Container } from "@mui/material";

import { Text, Button } from "@mantine/core";

import FarmAutomationImage from "../../../Assets/IMG/TransformMyPond/FarmAutomationImage.png";
import PondMonitoringImage from "../../../Assets/IMG/TransformMyPond/PondMonitoringImage.png";
import ConsultationImage from "../../../Assets/IMG/TransformMyPond/ConsultationImage.png";
import SecuritySystemImage from "../../../Assets/IMG/TransformMyPond/SecuritySystemImage.png";

import "./styles.scss";

export default function TransformMain() {
  return (
    <div className="main-container flex-col" data-aos="flip-up">
      <Container>
        <div
          className="transformations flex-col"
          data-aos="zoom-in-up"
          id="transformations"
        >
          <div
            className="transformation flex-row image-first-transformation align-center justify-between width-100"
            data-aos="flip-down"
          >
            <img
              src={FarmAutomationImage}
              alt=""
              className="image image-left"
            />
            <div className="content content-right flex-col justify-between">
              <Text className="subtitle">The Future Of Aquaculture</Text>
              <Text className="header" tt="uppercase">
                Farm Automation
              </Text>
              <Text className="description">
                The future of aquaculture is farms that operate with minimal
                manual intervention, where feeding schedules, water regulation,
                and environmental controls are all handled automatically. That’s
                the power of Farm Automation. Our system uses advanced sensors,
                AI algorithms, and IoT technology to automate essential tasks,
                ensuring your fish receive the optimal amount of feed at the
                right times, and your pond’s water quality is consistently
                maintained
              </Text>
              <Link to="/service/farm-automation">
                <Button className="btn">View More</Button>
              </Link>
            </div>
          </div>
          <div
            className="transformation flex-row image-last-transformation align-center justify-between width-100"
            data-aos="flip-down"
          >
            <div
              className="content content-left flex-col justify-between"
              data-aos="fade-right"
            >
              <Text className="subtitle">
                Stay Ahead with Real-Time Insights
              </Text>
              <Text className="header" tt="uppercase">
                Pond Monitoring
              </Text>
              <Text className="description">
                In aquaculture, knowledge is power. Pond Monitoring is your
                gateway to real-time data on all critical parameters affecting
                your fish's health and growth. From water temperature and
                dissolved oxygen levels to pH and turbidity, our system tracks
                it all, delivering instant insights to your fingertips.
              </Text>
              <Link to="/service/pond-monitoring">
                <Button className="btn">View More</Button>
              </Link>
            </div>
            <img
              src={PondMonitoringImage}
              alt=""
              className="image image-right"
              data-aos="fade-left"
            />
          </div>
          <div
            className="transformation flex-row image-first-transformation align-center justify-between width-100"
            data-aos="zoom-out"
          >
            <img src={ConsultationImage} alt="" className="image image-left" />
            <div className="content content-right flex-col justify-between">
              <Text className="subtitle">Expert Guidance for Your Succes</Text>
              <Text className="header" tt="uppercase">
                Consultation
              </Text>
              <Text className="description">
                Navigating the complexities of fish farming can be daunting, but
                you don’t have to do it alone. Our Consultation service provides
                you with direct access to industry experts who will guide you
                through every step of your aquaculture journey. Whether you’re
                just starting or looking to scale your operations, we offer
                tailored advice to ensure your success.
              </Text>
              <Link to="/service/consultation">
                <Button className="btn">View More</Button>
              </Link>
            </div>
          </div>
          <div className="transformation flex-row image-last-transformation align-center justify-between width-100">
            <div
              className="content content-left flex-col justify-between"
              data-aos="fade-left"
            >
              <Text className="subtitle">Safeguard Your Investment</Text>
              <Text className="header" tt="uppercase">
                Security System
              </Text>
              <Text className="description">
                In aquaculture, knowledge is power. Pond Monitoring is your
                gateway to real-time data on all critical parameters affecting
                your fish's health and growth. From water temperature and
                dissolved oxygen levels to pH and turbidity, our system tracks
                it all, delivering instant insights to your fingertips.
              </Text>
              <Link to="/service/security-system">
                <Button className="btn">View More</Button>
              </Link>
            </div>
            <img
              src={SecuritySystemImage}
              alt=""
              className="image image-right"
              data-aos="fade-right"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
