import React, { useEffect, createContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { MantineProvider } from "@mantine/core";
import Aos from "aos";

import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/notifications/styles.css";
import "./index.scss";
import "./tailwind.css";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Footer from "./Components/Footer";

import "aos/dist/aos.css";
import About from "./Pages/About";
import TransformMyPond from "./Pages/TransformMyPond";
import ServicePage from "./Pages/ServicePage";
import Farmers from "./Pages/Farmers";
import { usePerformRequest } from "./Lib/usePerformRequest";
import { NonPaginatedResponse } from "./Lib/Responses";
import { BlogPost, QuestionProps, Statistic } from "./Lib/Types";
import { Endpoints } from "./Lib/Endpoints";
import { AppContextProps } from "./Context/AppContext";
import Blog from "./Pages/Blog";
import ViewPost from "./Pages/ViewPost";
import { Notifications } from "@mantine/notifications";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <></>;
}
export const AppContext = createContext<AppContextProps>(undefined);
const App: React.FC = () => {
  Aos.init();

  const { data: questions } = usePerformRequest<
    NonPaginatedResponse<QuestionProps[]>
  >({
    method: "GET",
    url: Endpoints.GetQuestions,
  });
  const { data: stats } = usePerformRequest<NonPaginatedResponse<Statistic[]>>({
    method: "GET",
    url: Endpoints.GetStats,
  });
  const { data: recentPosts } = usePerformRequest<
    NonPaginatedResponse<BlogPost[]>
  >({
    method: "GET",
    url: Endpoints.GetRecentPosts,
  });
  const { data: featuredPost } = usePerformRequest<
    NonPaginatedResponse<BlogPost>
  >({
    method: "POST",
    url: Endpoints.GetBlogPosts,
    body: {
      page: 1,
      limit: 8,
    },
  });
  return (
    <MantineProvider>
      <Notifications
        position="top-right"
        style={{
          zIndex: 99999999,
        }}
      />
      <AppContext.Provider
        value={{
          questions: questions?.data,
          stats: stats?.data,
          recentPosts: recentPosts?.data,
          featuredPost: featuredPost?.data,
        }}
      >
        <Router>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/transform-my-pond" element={<TransformMyPond />} />
            <Route path="/service/:service-slug" element={<ServicePage />} />
            <Route path="/farmers" element={<Farmers />} />
            <Route path="/news" element={<Blog />} />
            <Route path="/post/:postID" element={<ViewPost />} />
          </Routes>
          <Footer />
        </Router>
      </AppContext.Provider>
    </MantineProvider>
  );
};

export default App;
