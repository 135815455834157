import { Container, Grid2, Grid2Props } from "@mui/material";

import { Text, Card } from "@mantine/core";

import CountUp from "react-countup";

import FarmersTrainedIcon from "../../../Assets/IMG/Home/Stats/farmer.png";
import ActivePondsIcon from "../../../Assets/IMG/Home/Stats/pond.png";
import FeedSavedIcon from "../../../Assets/IMG/Home/Stats/feed.png";
import MoneySavedIcon from "../../../Assets/IMG/Home/Stats/naira.png";
import "./styles.scss";
import { useInViewport } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";

export default function HomeStats() {
  const authContext = useContext(AppContext);
  const { ref, inViewport } = useInViewport();

  const icons = [
    ActivePondsIcon,
    FarmersTrainedIcon,
    FeedSavedIcon,
    MoneySavedIcon,
  ];

  const gridItemProps: Grid2Props = {
    size: {
      xs: 12,
      sm: 6,
      md: 3,
      lg: 3,
      xl: 3,
    },
  };
  const [isLoadedBefore, setLoadedBefore] = useState<boolean>(false);

  useEffect(() => {
    if (inViewport) {
      setLoadedBefore(true);
    }
  }, [inViewport]);
  const isShowingStats = inViewport || isLoadedBefore;
  return (
    <div className="stats-container" data-aos="fade-up" ref={ref}>
      <Container maxWidth="lg">
        <Text className="section-header">Stats</Text>
        <br />

        <br />
        {isShowingStats && (
          <Grid2 container spacing={2} justifyContent="space-between">
            {authContext?.stats?.map((stat, index) => {
              return (
                <Grid2 key={stat.id} {...gridItemProps}>
                  <Card shadow="xs" className="stat flex-col justify-between">
                    <div className="top flex-row justify-between">
                      <Text className="value">
                        <>
                          {/* @ts-ignore */}
                          {stat.numeric ? (
                            <>
                              <CountUp
                                duration={2.75}
                                start={1}
                                end={stat.value as any}
                              />
                              {stat.title.toLowerCase() === "feed saved"
                                ? "KG"
                                : ""}
                            </>
                          ) : (
                            <>{stat.value}</>
                          )}
                        </>
                      </Text>
                      <img src={icons[index]} alt="" className="image" />
                    </div>
                    <Text className="label">{stat.title} </Text>
                  </Card>
                </Grid2>
              );
            })}
          </Grid2>
        )}
      </Container>
    </div>
  );
}
