import { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";

import { Text, Button, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import { motion } from "framer-motion";

import ArrowRightIcon from "../../../Assets/IMG/ArrowRight.svg";

import CarouselImage1 from "../../../Assets/IMG/Home/JumboCarousel/1.jpg";
import CarouselImage2 from "../../../Assets/IMG/Home/JumboCarousel/2.jpg";
import CarouselImage3 from "../../../Assets/IMG/Home/JumboCarousel/3.jpg";
import CarouselImage4 from "../../../Assets/IMG/Home/JumboCarousel/4.jpg";
import CarouselImage5 from "../../../Assets/IMG/Home/JumboCarousel/5.jpg";

import "./styles.scss";
export default function HomeJumbotron() {
  const images = [
    CarouselImage1,
    CarouselImage2,
    CarouselImage3,
    CarouselImage4,
    CarouselImage5,
  ];
  const [currentImage, setCurrentImage] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 9000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="jumbotron-container relative h-screen">
      {/* Background */}
      <div className="jumbo-bg absolute inset-0 z-[-1] bg-cover bg-center"></div>

      {/* Container */}
      <div className="container mx-auto h-full flex flex-col justify-between px-6 lg:px-12">
        {/* Jumbotron */}
        <div className="jumbotron flex flex-col lg:flex-row items-center justify-between 2xl:justify-around h-full">
          {/* Left Section */}
          <div className="left max-w-xl flex flex-col justify-between space-y-6 lg:space-y-8 max-lg:mt-36">
            {/* Header */}
            <h1 className="header max-md:text-4xl text-5xl lg:text-6xl font-bold text-blue-800 leading-tight">
              The <br />
              Future <br />
              Aquaculture
            </h1>

            {/* Subtitle */}
            <p
              className="subtitle text-xl lg:text-2xl text-green-900"
              data-aos="fade-down"
            >
              Empowering African Fish Farmers with Smart and Efficient Solutions
              for a Sustainable Future
            </p>

            {/* Buttons */}
            <div className="left-row flex space-x-4" data-aos="fade-up">
              <Link to="/about">
                <button className="btn w-32 h-10 bg-blue-800 text-white rounded-md">
                  About Us
                </button>
              </Link>
              <a href="#reviews">
                <button className="reviews-btn w-40 h-10 border border-green-900 text-green-900 rounded-md flex items-center justify-center space-x-2">
                  <span>Reviews</span>
                  <img
                    className="arrow-icon w-4 h-4"
                    src={ArrowRightIcon}
                    alt="arrow"
                  />
                </button>
              </a>
            </div>
          </div>

          {/* Right Section */}
          <div className="right relative w-full max-w-lg mt-12 lg:mt-0 lg:w-[500px] max-lg:mb-[81px] h-[400px] max-sm:h-[300px] max-[375px]:hidden aspect-square">
            {images.map((image, index) => (
              <motion.div
                animate={{ opacity: currentImage === index ? 1 : 0 }}
                transition={{ duration: 3 }}
                className="image absolute inset-0 bg-cover bg-center"
                key={`${index}-${image}`}
                style={{ backgroundImage: `url(${image})` }}
              ></motion.div>
            ))}
            {/* Indicators */}
            <div className="indicators flex items-center justify-center space-x-2 mt-6 max-[375px]:hidden absolute right-0 left-0 -bottom-12">
              {images.map((_, index) => (
                <div
                  key={index}
                  className={`indicator w-5 h-5 rounded-full border transition-all duration-300 ${
                    currentImage === index
                      ? "bg-green-600 border-white scale-125"
                      : "bg-transparent border-green-600"
                  }`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
