import HomeBlog from "./home-blog";
import HomeFaq from "./home-faq";
import HomeJumbotron from "./home-jumbotron";
import HomeNewsletter from "./home-newsletter";
import HomeServices from "./home-services";
import HomeStats from "./home-stats";
import HomeCLients from "./home-clients";

import BackgroundVector from "../../Assets/IMG/BackgroundVector.png";
import "./styles.scss";
import FarmersComments from "../Farmers/farmers-comments";
import FarmersMontage from "../Farmers/farmers-montage";
import HomeClients2 from "./home-clients-2";

export default function Home() {
  return (
    <div className="home-container">
      <img src={BackgroundVector} alt="" className="background-vector-1" />
      <HomeJumbotron />
      <HomeServices />
      <HomeStats />
      {/* <HomeCLients /> */}
      <HomeClients2 />
      <HomeBlog />
      <HomeFaq />
      <HomeNewsletter />
      <img src={BackgroundVector} alt="" className="background-vector-2" />
    </div>
  );
}
