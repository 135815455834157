import {
  Container,
  Grid2 as Grid,
  Grid2Props as GridProps,
} from "@mui/material";

import { Text } from "@mantine/core";

import "./styles.scss";
export default function AboutValues() {
  const values = [
    {
      title: "INNOVATION",
      content:
        "Innovation empowers us to stay ahead in a rapidly evolving industry, adapt to changing regulations, and consistently meet the demands for high-quality, sustainably sourced seafood.",
    },
    {
      title: "FARMER-FOCUSED",
      content:
        "We place our farmers at the center of everything. We understand that their success is our success, which is why we are dedicated to providing them with the resources, and support they need to thrive",
    },
    {
      title: "SUSTAINABILITY",
      content:
        "We are deeply committed to protecting our environment while ensuring the long-term viability of our aquaculture practices.",
    },
    {
      title: "EXCELLENCE",
      content:
        "We believe that by maintaining rigorous standards and a relentless focus on quality, we can achieve outstanding results that set us apart in the industry.",
    },
  ];
  const gridItemProps: GridProps = {
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 12,
      xs: 12,
    },
  };
  return (
    <div className="values-container">
      <Container>
        <Text className="section-header">Values</Text>
        <div
          className="values flex-col align-center width-100"
          id="values-container"
        >
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent="center"
          >
            {values.map((value, index) => {
              const directions = ["down", "right", "up", "left"];
              const randomDirection =
                directions[Math.floor(Math.random() * directions.length)];
              return (
                <Grid
                  // @ts-ignore
                  data-aos={`fade-${randomDirection}`}
                  className="value flex-col"
                  key={index}
                  {...gridItemProps}
                >
                  <Text className="title" tt="uppercase">
                    {value.title}
                  </Text>
                  <Text className="content">{value.content}</Text>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Container>
    </div>
  );
}
