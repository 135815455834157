import { useNavigate, Link } from "react-router-dom";

import {
  Container,
  Grid2 as Grid,
  Grid2Props as GridProps,
} from "@mui/material";

import {
  Text,
  Button,
  NavLink,
  Card,
  useMantineTheme,
  rem,
  ActionIcon,
  Pagination,
  Popover,
  Divider,
} from "@mantine/core";

import {
  IconEdit,
  IconExternalLink,
  IconPencilPlus,
  IconReload,
  IconTrash,
  IconUserPlus,
} from "@tabler/icons-react";

import "./styles.scss";
import { useMediaQuery } from "@mantine/hooks";
import { useContext, useState } from "react";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { NonPaginatedResponse, PaginatedResponse } from "../../Lib/Responses";
import { BlogPost } from "../../Lib/Types";
import Loader from "../../Components/Loader";
import { AppContext } from "../../App";
import { getEllipsisWithString } from "../../Lib/Methods";

export default function Blog() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [pagination, setPagination] = useState<{ page: number; limit: number }>(
    {
      page: 1,
      limit: 20,
    }
  );
  const {
    data: posts,
    isLoading: isLoadingPosts,
    reloadData: reloadPosts,
  } = usePerformRequest<PaginatedResponse<BlogPost[]>>({
    method: "POST",
    url: Endpoints.GetBlogPosts,
    body: pagination,
  });

  const gridItemProps: GridProps = {
    size: {
      xl: 3,
      lg: 3,
      md: 6,
      sm: 12,
      xs: 12,
    },
  };
  return (
    <div className="blogs-container">
      {context && (
        <Container maxWidth="xl">
          <div className="top flex-row justify-between width-100">
            <Link
              to={`/post/${context?.featuredPost?.id}`}
              className="featured-post flex-col justify-end"
              style={{
                backgroundImage: `url(${context?.featuredPost?.thumbnail})`,
              }}
            >
              <div className="content flex-col">
                <span className="tag">{context?.featuredPost?.tag}</span>
                <Text className="title">{context?.featuredPost?.title}</Text>
              </div>
            </Link>
            <div className="flex-col recent-stories">
              <div className="header-row flex-row align-center justify-between width-100">
                <Text className="header">RECENT STORIES</Text>
                <Divider color="var(--green)" className="divider" />
              </div>
              <Card withBorder>
                <div className="stories flex-col width-100 justify-between">
                  {context?.recentPosts?.map((post) => {
                    return (
                      <Link
                        to={`/post/${post.id}`}
                        key={post.id}
                        className="story flex-row justify-between"
                      >
                        <div className="left flex-col">
                          <Text className="title">
                            {getEllipsisWithString(post.title, 50)}
                          </Text>
                          <span className="tag">{post.tag}</span>
                        </div>
                        <img
                          src={post.thumbnail}
                          alt=""
                          className="thumbnail"
                        />
                      </Link>
                    );
                  })}
                </div>
              </Card>
            </div>
          </div>
          <Container maxWidth="lg">
            {isLoadingPosts ? (
              <Loader />
            ) : (
              <>
                {posts && posts.data && (
                  <Grid
                    container
                    className="posts"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {posts?.data
                      ?.filter((p) => p.published === true)
                      ?.map((post, index) => {
                        return (
                          <Grid
                            className="post flex-col justify-between"
                            style={{
                              backgroundImage: `url(${post.thumbnail})`,
                            }}
                            key={post.id}
                            {...gridItemProps}
                            // @ts-ignore
                            onClick={() => {
                              navigate(`/post/${post.id}`);
                            }}
                          >
                            <div className="top flex-col">
                              <Text className="tag" tt="uppercase">
                                {post.tag}
                              </Text>
                              <Text className="title">
                                {getEllipsisWithString(post.title, 50)}
                              </Text>
                            </div>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              </>
            )}
            <div className="flex-row width-100 justify-center align-center">
              <Pagination
                mt={10}
                disabled={isLoadingPosts}
                value={pagination.page}
                onChange={(e) => {
                  setPagination({ ...pagination, page: e });
                }}
                total={Math.ceil((posts?.totalRecords as number) / 20)}
              />
            </div>
          </Container>
        </Container>
      )}
    </div>
  );
}
