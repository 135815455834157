import { Container } from "@mui/material";

import { Text, TextInput, Button } from "@mantine/core";

import NewsletterImage from "../../../Assets/IMG/Home/NewsletterImage.png";

import "./styles.scss";
import { IconMail } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
} from "../../../Lib/Methods";
import { PerformRequest } from "../../../Lib/usePerformRequest";
import { Endpoints } from "../../../Lib/Endpoints";
import { useState } from "react";
export default function HomeNewsletter() {
  const newsletterForm = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Please enter a valid email",
    },
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const JoinNewsletter = async () => {
    const { hasErrors } = newsletterForm.validate();
    if (!hasErrors) {
      const { email } = newsletterForm.values;
      setLoading(true);
      const r = await PerformRequest({
        method: "POST",
        route: Endpoints.JoinNewsletter,
        data: { email },
        callback: () => {
          setLoading(false);
        },
      });
      setLoading(false);
      if (r && r.status === 201) {
        DefaultSuccessNotification("You have been added to our newsletter!");
      } else {
        DefaultErrorNotification("An error occurred!");
      }
    }
  };
  return (
    <div className="newsletter-container">
      <Container maxWidth="lg">
        <div className="newsletter flex-row align-center justify-between">
          <img src={NewsletterImage} alt="" className="image" />
          <div className="right flex-col justify-between" data-aos="fade-left">
            <Text className="section-header header">
              Subscribe to our Newsletter
            </Text>
            <Text className="dimmed">
              Stay in the loop with the latest from FutureFish
            </Text>
            <TextInput
              leftSection={<IconMail />}
              placeholder="Your email"
              {...newsletterForm.getInputProps("email")}
              variant="filled"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  JoinNewsletter();
                }
              }}
              styles={{
                input: {
                  height: 45,
                  paddingLeft: 40,
                },
              }}
            />
            <Button
              loading={isLoading}
              onClick={JoinNewsletter}
              color="var(--blue-primary)"
            >
              Subscribe
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}
