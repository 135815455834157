import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  Container,
  Grid2 as Grid,
  Grid2Props as GridProps,
} from "@mui/material";

import {
  ActionIcon,
  Button,
  Divider,
  Text,
  Textarea,
  TextInput,
  UnstyledButton,
} from "@mantine/core";

import { useForm } from "@mantine/form";

// BEGIN IMAGE IMPORTS

import FarmAutomationHeroImage from "../../Assets/IMG/Services/FarmAutomationHero.png";
import ConsultationHeroImage from "../../Assets/IMG/Services/ConsultationHero.png";
import PondMonitoringHeroImage from "../../Assets/IMG/Services/PondMonitoringHero.png";
import SecuritySystemHeroImage from "../../Assets/IMG/Services/SecuritySystemHero.png";

import IncreasedProductivityIcon from "../../Assets/IMG/Services/ProductivityIcon.png";
import CostEfficiencyIcon from "../../Assets/IMG/Services/CostEfficiencyIcon.png";
import PeaceOfMindIcon from "../../Assets/IMG/Services/PeaceOfMindIcon.png";
import FishHealthIcon from "../../Assets/IMG/Services/FishHealthIcon.png";
import InformedDecisionsIcon from "../../Assets/IMG/Services/InformedDecisionsIcon.png";
import ResourceEfficiencyIcon from "../../Assets/IMG/Services/ResourceEfficiencyIcon.png";
import ConfidenceIcon from "../../Assets/IMG/Services/ConfidenceIcon.png";
import MistakesIcon from "../../Assets/IMG/Services/MistakesIcon.png";
import ProfitabilityIcon from "../../Assets/IMG/Services/ProfitabilityIcon.png";
import DeterrenceIcon from "../../Assets/IMG/Services/DeterrenceIcon.png";
import ReducedLossesIcon from "../../Assets/IMG/Services/ReducedLossesIcon.png";
import ProtectionIcon from "../../Assets/IMG/Services/ProtectionIcon.png";

import AutomatedFeedingCardImage from "../../Assets/IMG/Services/AutomatedFeedingCard.png";
import RemoteControlCardImage from "../../Assets/IMG/Services/RemoteControlCard.png";
import WaterRegulationCardImage from "../../Assets/IMG/Services/WaterRegulationCard.png";
import MonitoringCardImage from "../../Assets/IMG/Services/MonitoringCard.png";
import DataLoggingCardImage from "../../Assets/IMG/Services/DataLoggingCard.png";
import AlertsNotificationsCardImage from "../../Assets/IMG/Services/AlertsNotificationsCard.png";
import PersonalizedConsultationCardImage from "../../Assets/IMG/Services/PersonalizedConsultationCard.png";
import StrategicPlanningCardImage from "../../Assets/IMG/Services/StrategicPlanningCard.png";
import TechnicalSupportCardImage from "../../Assets/IMG/Services/TechnicalSupportCard.png";
import AccessControlCardImage from "../../Assets/IMG/Services/AccessControlCard.png";
import MotionDetectionCardImage from "../../Assets/IMG/Services/MotionDetectionCard.png";
import SurveillanceCardImage from "../../Assets/IMG/Services/SurveillanceCard.png";

// END IMAGE IMPORTS

import BackgroundVector from "../../Assets/IMG/BackgroundVector.png";

import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
} from "../../Lib/Methods";
import "./styles.scss";
import {
  IconBuildingCottage,
  IconMessages,
  IconRipple,
  IconShieldLock,
} from "@tabler/icons-react";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";

const slugs = [
  "farm-automation",
  "pond-monitoring",
  "security-system",
  "consultation",
];

const services = [
  {
    slug: "farm-automation",
    name: "Farm Automation",
    hero: FarmAutomationHeroImage,
    icon: IconBuildingCottage,
    header: "The Future Of Aquaculture",
    benefits: [
      {
        image: IncreasedProductivityIcon,
        title: "INCREASED PRODUCTIVITY",
        content:
          "Automation eliminates human error, ensuring consistent care and optimal growth conditions for your fish.",
      },
      {
        image: CostEfficiencyIcon,
        title: "Cost Efficiency",
        content:
          "Reduce operational costs by minimizing labor and resource waste, saving you time and money.",
      },
      {
        image: PeaceOfMindIcon,
        title: "Peace of Mind",
        content:
          "With automation, your farm runs smoothly even when you’re not there, giving you the freedom to focus on scaling your business.",
      },
    ],
    p1: "The future of aquaculture is farms that operate with minimal manual intervention, where feeding schedules, water regulation, and environmental controls are all handled automatically. That’s the power of Farm Automation. Our system uses advanced sensors, AI algorithms, and IoT technology to automate essential tasks, ensuring your fish receive the optimal amount of feed at the right times, and your pond’s water quality is consistently maintained.",
    p2: "Without Farm Automation, you’re leaving your pond’s potential untapped. Manual operations can lead to inconsistent feeding, poor water quality, and ultimately, lower yields. Don’t let outdated methods hold you back—transform your pond today with our state-of-theart automation solutions.",
    features: [
      {
        title: "Automated Feeding",
        image: AutomatedFeedingCardImage,
        content: "Schedule and monitor feeding times without lifting a finger.",
      },
      {
        title: "Water Regulation",
        image: WaterRegulationCardImage,
        content: "Maintain ideal water conditions automatically 24/7.",
      },
      {
        title: "Remote Control",
        image: RemoteControlCardImage,
        content:
          "Manage your farm from anywhere in the world via our intuitive mobile app.",
      },
    ],
  },
  {
    slug: "pond-monitoring",
    name: "Pond Monitoring",
    icon: IconRipple,
    hero: PondMonitoringHeroImage,
    header: "Stay Ahead With Real-Time Insights",
    benefits: [
      {
        image: FishHealthIcon,
        title: "Optimal Fish Health",
        content:
          "Consistently monitor and adjust water conditions to prevent disease outbreaks and ensure peak fish health.",
      },
      {
        image: ResourceEfficiencyIcon,
        title: "Resource Efficiency",
        content:
          "Detect and resolve issues before they escalate, reducing wastage of feed, water, and other resources",
      },
      {
        image: InformedDecisionsIcon,
        title: "Informed Decisions",
        content:
          "Leverage data to make precise adjustments that enhance growth rates and yield.",
      },
    ],
    p1: "In aquaculture, knowledge is power. Pond Monitoring is your gateway to real-time data on all critical parameters affecting your fish's health and growth. From water temperature and dissolved oxygen levels to pH and turbidity, our system tracks it all, delivering instant insights to your fingertips.",
    p2: "Without robust Pond Monitoring, you’re farming blind. Unforeseen changes in water quality can lead to stress, disease, or even fish loss. Equip yourself with the tools to monitor and respond in real-time, ensuring your pond operates at its best, day in and day out.",
    features: [
      {
        title: "24/7 Monitoring",
        image: MonitoringCardImage,
        content: "Continuous tracking of vital water parameters.",
      },
      {
        title: "Data Logging",
        image: DataLoggingCardImage,
        content:
          "Historical data stored securely for trend analysis and decision-making.",
      },
      {
        title: "Alerts & Notifications",
        image: AlertsNotificationsCardImage,
        content:
          "Instant alerts on your mobile device for any deviations from ideal conditions.",
      },
    ],
  },
  {
    slug: "consultation",
    name: "Consultation",
    icon: IconMessages,
    hero: ConsultationHeroImage,
    header: "Expert Guidance for Your Success",
    benefits: [
      {
        image: ConfidenceIcon,
        title: "Increased Confidence",
        content:
          "With expert advice, make informed decisions that lead to better outcomes.",
      },
      {
        image: MistakesIcon,
        title: "Avoid Costly Mistakes",
        content:
          "Learn from the experiences of seasoned professionals and sidestep common pitfalls.",
      },
      {
        image: ProfitabilityIcon,
        title: "Enhanced Profitability",
        content:
          "Optimize every aspect of your operation, from pond management to marketing, to maximize returns.",
      },
    ],
    p1: "Navigating the complexities of fish farming can be daunting, but you don’t have to do it alone. Our Consultation service provides you with direct access to industry experts who will guide you through every step of your aquaculture journey. Whether you’re just starting or looking to scale your operations, we offer tailored advice to ensure your success.",
    p2: "Without expert consultation, you risk making costly mistakes that could set your operation back. Invest in knowledge and watch your pond flourish under the guidance of professionals who are dedicated to your success.",
    features: [
      {
        title: "Personalized Consultation",
        image: PersonalizedConsultationCardImage,
        content:
          "One-on-one sessions with aquaculture experts who understand your unique challenges.",
      },
      {
        title: "Strategic Planning",
        image: StrategicPlanningCardImage,
        content:
          "Assistance in developing long-term plans for sustainable growth and profitability.",
      },
      {
        title: "Technical Support",
        image: TechnicalSupportCardImage,
        content:
          "Solutions to technical issues and guidance on implementing best practices.",
      },
    ],
  },
  {
    slug: "security-system",
    name: "Security System",
    icon: IconShieldLock,
    hero: SecuritySystemHeroImage,
    header: "Safeguard Your Investment",
    benefits: [
      {
        image: DeterrenceIcon,
        title: "Deterrence",
        content:
          "Visible security measures discourage potential thieves and vandals.",
      },
      {
        image: ProtectionIcon,
        title: "24/7 Protection",
        content:
          "Monitor your farm from anywhere, ensuring it’s always safe and secure.",
      },
      {
        image: ReducedLossesIcon,
        title: "Reduced Losses",
        content:
          "Prevent theft and damage that could lead to significant financial loss.",
      },
    ],
    p1: "Your fish farm is a valuable asset—don’t leave it unprotected. Our Security System is designed to keep your operation safe from theft, vandalism, and unauthorized access. With high-definition CCTV cameras, motion sensors, and secure access controls, you can monitor your farm around the clock, ensuring that your investment is always secure.",
    p2: "Without a robust Security System, your farm is vulnerable to theft and vandalism, which could result in devastating losses. Protect your investment and ensure peace of mind with our comprehensive security solutions.",
    features: [
      {
        title: "CCTV Surveillance",
        image: SurveillanceCardImage,
        content:
          "High-definition cameras with night vision and remote viewing capabilities.",
      },
      {
        title: "Motion Detection",
        image: MotionDetectionCardImage,
        content: "Instant alerts triggered by any suspicious activity.",
      },
      {
        title: "Access Control",
        image: AccessControlCardImage,
        content: "Secure your farm with smart locks and access cards.",
      },
    ],
  },
];
export default function ServicePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const path = pathname
    .replaceAll("service", "")
    .replaceAll("/", "")
    .toLowerCase();
  const service = services.find((s) => s.slug === path);
  useEffect(() => {
    if (!slugs.includes(path)) {
      navigate("/transform-my-pond");
    }
  }, [location]);

  const gridItemProps: GridProps = {
    size: {
      xl: 6,
      lg: 6,
    },
    className: "benefit flex-col",
  };

  const orderForm = useForm({
    initialValues: {
      name: "",
      email: "",
      information: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Please enter a valid email",
      name: (value) => (value.length > 0 ? null : "Please enter your name"),
    },
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const CreateOrder = async () => {
    const { hasErrors } = orderForm.validate();
    if (!hasErrors) {
      setLoading(true);
      const { name, email, information } = orderForm.values;
      const r = await PerformRequest({
        method: "POST",
        route: Endpoints.CreateOrder,
        data: {
          name,
          email,
          comments: information,
          service: service?.slug,
        },
        callback: () => {
          setLoading(false);
        },
      });
      setLoading(false);
      if (r?.status === 201) {
        DefaultSuccessNotification(r.message);
        orderForm.reset();
      }
    } else {
      DefaultErrorNotification("Please fill the order form!");
    }
  };
  return (
    <div className="service-container">
      {service && (
        <Container>
          <div className="jumbotron flex-row width-100 justify-between">
            <div className="left flex-col" data-aos="fade-left">
              <img
                src={service.hero}
                alt=""
                className="hero"
                data-aos="fade-down"
              />
              <a href="#order-form">
                <Button
                  w={140}
                  variant="outline"
                  color="var(--blue-primary)"
                  data-aos="fade-up"
                  className="order-btn"
                >
                  Order Now
                </Button>
              </a>
            </div>
            <div className="right flex-col">
              <Text className="name">{service.name}</Text>
              <Grid container spacing={0} justifyContent="space-between">
                {service.benefits.map((benefit, index) => {
                  const directions = ["down", "right", "up", "left"];
                  const randomDirection =
                    directions[Math.floor(Math.random() * directions.length)];
                  return (
                    <Grid
                      key={index}
                      {...gridItemProps}
                      // @ts-ignore
                      data-aos={`fade-${randomDirection}`}
                    >
                      <img src={benefit.image} alt="" className="image" />
                      <Text className="title" tt="uppercase">
                        {benefit.title}
                      </Text>
                      <Text className="content">{benefit.content}</Text>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
          <Text className="title" tt="capitalize">
            {service.header}
          </Text>
          <Text className="paragraph" data-aos="fade-up">
            {service.p1}
          </Text>
          <img src={BackgroundVector} alt="" className="background-vector-1" />
          <img src={BackgroundVector} alt="" className="background-vector-2" />
          <div className="features flex-row align-center justify-between width-100">
            {service.features.map((feature, index) => {
              const directions = ["down", "right", "up", "left"];
              const randomDirection =
                directions[Math.floor(Math.random() * directions.length)];
              return (
                <div
                  data-aos={`flip-${randomDirection}`}
                  className="feature flex-col justify-end"
                  style={{
                    backgroundImage: `url(${feature.image})`,
                  }}
                >
                  <Text className="title" tt="capitalize">
                    {feature.title}
                  </Text>
                  <Divider w="100%" color="var(--white)" />
                  <Text className="dimmed">{feature.content}</Text>
                </div>
              );
            })}
          </div>
          <Text className="paragraph" data-aos="fade-up">
            {service.p2}
          </Text>
          <Text id="order-form" className="section-header">
            Order Now
          </Text>
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              CreateOrder();
            }}
            className="form flex-col align-center"
            data-aos="zoom-in"
          >
            <div className="row flex-row align-center justify-between width-100">
              <TextInput
                label="Name"
                className="input"
                classNames={{
                  input: "text-input",
                }}
                placeholder="Enter your full name"
                {...orderForm.getInputProps("name")}
              />
              <TextInput
                label="Email"
                className="input"
                classNames={{
                  input: "text-input",
                }}
                placeholder="Enter your email address "
                {...orderForm.getInputProps("email")}
              />
            </div>
            <Textarea
              w="100%"
              classNames={{
                input: "textarea",
              }}
              label="Additional Information (Optional)"
              placeholder="Enter your comments..."
              {...orderForm.getInputProps("information")}
            />
            <Button
              color="var(--blue-primary)"
              w={200}
              type="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </form>
          <div className="tabs-container flex-row align-center justify-center">
            <div className="tabs flex-row align-center justify-between">
              {slugs.map((s) => {
                const slug = s;
                const serVICE = services.find((sr) => sr.slug === slug);
                return (
                  <Link to={`/service/${slug}`}>
                    <Button
                      color="var(--blue-primary)"
                      variant={slug === service.slug ? "filled" : "subtle"}
                      tt="capitalize"
                    >
                      {serVICE?.name}
                    </Button>
                  </Link>
                );
              })}
            </div>
            <div className="small-tabs flex-row align-center justify-between">
              {slugs.map((s) => {
                const slug = s;
                const serVICE = services.find((sr) => sr.slug === slug);
                return (
                  <Link to={`/service/${slug}`}>
                    <ActionIcon
                      color="var(--blue-primary)"
                      variant={slug === service.slug ? "filled" : "subtle"}
                      size="xl"
                    >
                      {serVICE && <serVICE.icon />}
                    </ActionIcon>
                  </Link>
                );
              })}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
