import { notifications } from "@mantine/notifications";

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export const getRandomItem = (array: any[]) => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};
export const getEllipsisWithString = (string: string, length: number) => {
  if (string.length < length - 5) {
    return string;
  } else {
    return `${string.substring(0, length)}...`;
  }
};
export const DefaultErrorNotification = (message?: string, title?: string) => {
  notifications.show({
    color: "red",
    message: message ?? "An error occurred",
    title: title ?? "Error!",
  });
};
export const DefaultSuccessNotification = (
  message?: string,
  title?: string
) => {
  notifications.show({
    color: "green",
    message: message ?? "Completed!",
    title: title ?? "Success!",
  });
};
