import TransformJumbo from "./transform-jumbo";
import TransformMain from "./transform-main";

import "./styles.scss";
export default function TransformMyPond() {
  return (
    <div className="transform-container">
      <TransformJumbo />
      <TransformMain />
    </div>
  );
}
