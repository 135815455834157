import FarmersComments from "./farmers-comments";
import FarmersMontage from "./farmers-montage";

import BackgroundVector from "../../Assets/IMG/BackgroundVector.png";

import "./styles.scss";
import FarmersArticles from "./farmers-articles";
export default function Farmers() {
  return (
    <div className="farmers-container">
      <FarmersMontage />
      <img src={BackgroundVector} alt="" className="background-vector-1" />

      <FarmersComments />
      <FarmersArticles />
    </div>
  );
}
