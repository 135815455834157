import MeetTheTeam from "./about-team";
import AboutValues from "./about-values";
import VisionMission from "./about-vision-mission";

import BackgroundVector from "../../Assets/IMG/BackgroundVector.png";

import "./styles.scss";

export default function About() {
  return (
    <div className="about-container">
      <MeetTheTeam />
      <AboutValues />
      <VisionMission />
      <img src={BackgroundVector} alt="" className="background-vector-1" />
      <img src={BackgroundVector} alt="" className="background-vector-2" />
    </div>
  );
}
