import { Container } from "@mui/material";

import { Text } from "@mantine/core";

// @ts-ignore
import FishesMontage from "../../../Assets/Videos/FishesMontage.mp4";
import MouseCursorDown from "../../../Assets/IMG/TransformMyPond/MouseCursor.png";

import "./styles.scss";
export default function TransformJumbo() {
  return (
    <div className="jumbo-container">
      <Container maxWidth="lg"></Container>
      <div className="jumbotron flex-col align-center width-100 justify-end">
        <Text tt="uppercase" className="header">
          Transform My Pond
        </Text>

        <img
          onClick={() => {
            // Scroll to main section
            window.scrollTo({
              top: window.innerHeight,
            });
          }}
          src={MouseCursorDown}
          alt=""
          className="mouse-image"
        />
      </div>
      <video className="jumbo-video" loop muted autoPlay>
        <source src={FishesMontage} type="video/mp4" />
      </video>
    </div>
  );
}
