import {
  Container,
  Grid2 as Grid,
  Grid2Props as GridProps,
} from "@mui/material";

import { Button, Divider, Text, Modal, ActionIcon } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import KhalifaImage from "../../../Assets/IMG/Team/khalifa.png";
import MelaImage from "../../../Assets/IMG/Team/mela.png";
import KolapoImage from "../../../Assets/IMG/Team/kolapo.png";
import TaiwoImage from "../../../Assets/IMG/Team/taiwo.png";
import KehindeImage from "../../../Assets/IMG/Team/kehinde.png";

import "./styles.scss";
import { useState } from "react";
import { IconBrandLinkedin, IconX } from "@tabler/icons-react";
import { openModal } from "@mantine/modals";

export default function MeetTheTeam() {
  interface MemberProps {
    image: string;
    firstName: string;
    lastName: string;
    position: string;
    middleName?: string;
    link: string;
    bio: string;
  }
  const team: MemberProps[] = [
    {
      image: KhalifaImage,
      firstName: "Khalifa",
      lastName: "Shuaib",
      position: "CEO",
      link: "https://www.linkedin.com/in/khalifa-shuaib-352338229/",
      bio: "Khalifa Shuaib stands out as a visionary leader, combining technology with aquaculture. With a first-class degree in Mechatronics Engineering, Khalifa brings an extensive skill set in software engineering, design and embedded systems, all honed through years of practical experience. As the CEO of Future Fish AgroTech, he leverages these technical skills to revolutionise traditional aquaculture practices using cutting-edge technology. Beyond his technical prowess, Khalifa's business acumen has been shaped by co-founding two other successful startups. This entrepreneurial experience, coupled with his deep understanding of technology, makes him exceptionally well-suited to lead Future Fish AgroTech. His vision is to simplify  the industry, making fish farming more efficient, secure, and profitable for all.",
    },
    {
      image: KehindeImage,
      firstName: "Kehinde",
      lastName: "Adedeji",
      position: "COO & Co-Founder",
      link: "https://www.linkedin.com/in/kehinde-adedeji-4785b3202?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      bio: "Kehinde Adedeji is an industrial Automation Engineer experienced in design and integration Of control systems for manufacturing industries cutting across FMCG, Oil and Gas , Paper and Textile industries. With key interests in climate smart Agriculture and sustainable manufacturing, he worked actively on a prototype model for a wheeled robot for the autonomous planting of Maize seeds, He’s actively. As the Co-Founder and Chief Operations Officer at Future Fish, Kehinde takes the lead in operation and business management.",
    },
    {
      image: MelaImage,
      firstName: "Mela",
      lastName: "Obi-Obuoha",
      position: "CTO",
      link: "http://linkedin.com/in/abiamamela-obi-obuoha-108a13280",
      bio: "Abiamamela is a top performing mechatronics engineer with a first-class bachelor’s degree from Afe Babalola University. He has a strong foundation in machine learning and data analytics, backed by over three years of practical experience. Currently, Abiamamela works as an AI facilitator at the National Center for Artificial Intelligence and Robotics. He also serves as the Chief Technology Officer at Future Fish Agrotech, where he applies his expertise to develop innovative solutions. Abiamamela has a diverse background, having gained valuable experience in modeling, simulation, and design at the National Space Research and Development Agency. He further honed his skills in programming during his internship at the National Institute of Information Technology.",
    },
    {
      image: KolapoImage,
      firstName: "Kolapo",
      lastName: "Adeniji",
      position: "CHIEF MARKETING OFFICER",
      link: "https://www.linkedin.com/in/kolapo-adeniji-130952325?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      bio: "Adeniji Kolapo is Chief Marketing Officer and an Aquaculture consultant for Future Fish AgroTech. With a Bachelor's degree in Agronomy from Babcock University, Kolapo has developed a robust foundation in agribusiness through his work at Niji Agro Services where he worked with their Agro fabrication department and their food processing department. His passion for agriculture and aquaculture led him to serve at the International Institute of Tropical Agriculture (IITA), where he worked with the Business Incubation Platform (IITA BIP) as a research assistant. Driven by a deep-seated love for aquaculture, Kolapo has also gathered years of experience managing large fish farms. He currently uses his expertise and vision to shape the future of the industry. Through Future Fish AgroTech, Kolapo is committed to leveraging cutting-edge technology to revolutionize aquaculture practices and make a meaningful impact on the future of fish farming.",
    },
    {
      image: TaiwoImage,
      firstName: "Taiwo",
      lastName: "Adedeji",
      middleName: "Moshood",
      position: "CHIEF FINANCE OFFICER",
      bio: "Adedeji Taiwo Moshood is the Aquaculture Specialist at Futurefish Agrotech. He holds a Bachelor’s degree in Animal Science from ABUAD. With a strong foundation in quality assurance from his time at Livestock Feeds Plc. His passion for aquaculture led him to Olam Agri, where he served as an Aquaculture Technical Expert. In this role, Taiwo worked closely with fish farmers across Kwara State, providing them with the latest industry insights and farm practices to optimize their operations and boost profitability. Committed to the welfare of fish farmers, Taiwo is dedicated to helping them utilise their resources effectively for maximum outcomes.",
      link: "https://www.linkedin.com/in/taiwo-a-15aa34277?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
  ];
  const gridItemProps: GridProps = {};

  //   BEGIN MODAL FUNCTIONALITY
  const [currrentTeamMember, setCurrentTeamMember] = useState<MemberProps>();
  const [isModalOpen, { open: OpenModal, close: CloseModal }] =
    useDisclosure(false);
  //   END MODAL FUNCTIONALITY
  return (
    <div className="team-container" id="team-container">
      <Container maxWidth="lg">
        <Text className="section-header">Meet the Team</Text>
        <div className="team flex-col align-center">
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            {team.map((member, index) => {
              const green = (index + 1) % 2 === 0;
              const directions = ["down", "right", "up", "left"];
              const randomDirection =
                directions[Math.floor(Math.random() * directions.length)];
              return (
                <>
                  <Grid
                    className={`member flex-col justify-end member-${
                      green ? "green" : "blue"
                    }`}
                    {...gridItemProps}
                    key={member.position}
                    // @ts-ignore
                    data-aos={`fade-${randomDirection}`}
                    // @ts-ignore
                    onClick={() => {
                      setCurrentTeamMember(member);
                      OpenModal();
                    }}
                  >
                    <div className="content flex-col">
                      <Text className="position" tt="uppercase">
                        {member.position}
                      </Text>
                      <Divider size="xl" color="var(--white)" w={100} my={7} />
                      <Text className="name" tt="uppercase">
                        {member.firstName}
                      </Text>
                      <Text className="name" tt="uppercase">
                        {member.lastName}
                      </Text>
                    </div>
                    <div
                      className="member-bg"
                      style={{
                        backgroundImage: `url(${member.image})`,
                      }}
                    ></div>
                    <div className="member-overlay"></div>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </div>
      </Container>

      <Modal
        title={undefined}
        styles={{
          body: {
            padding: 0,
          },
          header: {
            display: "none",
          },
        }}
        zIndex={9999999999}
        className="team-modal"
        closeButtonProps={{
          style: {
            display: "none",
          },
        }}
        opened={isModalOpen}
        onClose={CloseModal}
        centered
        size="xl"
      >
        <>
          {currrentTeamMember && (
            <div className="profile flex-col">
              <div className="top flex-row justify-between flex-row">
                <img src={currrentTeamMember.image} alt="" className="image" />
                <ActionIcon
                  variant="subtle"
                  color="var(--darkest-green)"
                  onClick={CloseModal}
                >
                  <IconX />
                </ActionIcon>
              </div>
              <div className="content flex-col width-100">
                <Text className="name" tt="capitalize">
                  {currrentTeamMember.firstName} {currrentTeamMember.lastName}{" "}
                  {currrentTeamMember?.middleName}
                </Text>
                <a
                  className="link"
                  href={currrentTeamMember.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ActionIcon
                    variant="subtle"
                    size="lg"
                    color="var(--blue-primary)"
                  >
                    <IconBrandLinkedin />
                  </ActionIcon>
                </a>
                <Text className="bio">{currrentTeamMember.bio}</Text>
                <Button
                  className="close"
                  color="var(--green)"
                  w={150}
                  onClick={CloseModal}
                >
                  Close
                </Button>
              </div>
            </div>
          )}
        </>
      </Modal>
    </div>
  );
}
