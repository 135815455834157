import { Link } from "react-router-dom";
import {
  Container,
  Grid2 as Grid,
  Grid2Props as GridProps,
} from "@mui/material";

import { Text } from "@mantine/core";

import Logo from "../../Assets/IMG/LogoFull.svg";

import "./styles.scss";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandX,
  IconBrandYoutube,
  IconBrandYoutubeFilled,
  IconX,
} from "@tabler/icons-react";

export default function Footer() {
  const footerItems = [
    {
      header: "About",
      items: [
        {
          link: "/about#team-container",
          label: "Overview",
        },
        {
          link: "/about#values-container",

          label: "Values",
        },
        // { link: "#", label: "Roadmap" },
        { link: "/about#team-container", label: "Team" },
      ],
    },
    {
      header: "Community",
      items: [
        {
          link: "/farmers",
          label: "Farmers",
        },
        {
          link: "https://x.com/FutureFishAgro",
          label: "Follow on X",
          newTab: true,
        },
        {
          newTab: true,
          link: "https://instagram.com/futurefishagro",
          label: "Instagram",
        },
        {
          link: "/news",
          label: "Blog",
        },
        {
          newTab: true,
          link: "https://www.youtube.com/channel/UCV-4gU0UHWozSuU5LD_mChw",
          label: "YouTube Channel",
        },
      ],
    },
    {
      header: "Services",
      items: [
        {
          link: "/service/farm-automation",
          label: "Farm Automation",
        },
        { link: "/service/pond-monitoring", label: "Pond Monitoring" },
        { link: "/service/consultation", label: "Consultation" },
        { link: "/service/security-system", label: "Security System" },
      ],
    },
  ];
  const gridItemProps: GridProps = {};
  const GoHome = () => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
  };
  return (
    <div id="contact" className="footer-container" data-aos="fade-up">
      <Container maxWidth="lg">
        <footer className="footer flex-row align-start width-100 justify-between">
          <div className="left flex-col">
            <Link to="/" onClick={GoHome}>
              <img src={Logo} alt="" className="logo" />
            </Link>
            <Text className="dimmed">
              Empowering African Fish Farmers with Smart and Efficient Solutions
              for a Sustainable Future
            </Text>
          </div>
          <Grid container spacing={10} justifyContent="space-between">
            {footerItems.map((item) => {
              return (
                <Grid {...gridItemProps} className="footer-item flex-col">
                  <Text className="header">{item.header}</Text>
                  {item.items.map((link) => {
                    return (
                      <Link
                        to={link.link}
                        target={link.newTab ? "_blank" : ""}
                        className="link"
                      >
                        <Text className="label">{link.label}</Text>
                      </Link>
                    );
                  })}
                  {/* </div> */}
                </Grid>
              );
            })}
          </Grid>
        </footer>
        <div className="bottom flex-row align-center width-100 justify-between">
          <Text className="dimmed">
            &copy; {new Date(Date.now()).getFullYear()} FutureFish. All rights
            reserved.
          </Text>
          <div className="social flex-row align-center">
            <a
              href="https://x.com/FutureFishAgro"
              className="link"
              target="_blank"
            >
              <IconBrandX color="var(--blue-primary)" size={20} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61564849314886&mibextid=LQQJ4d"
              className="link"
              target="_blank"
            >
              <IconBrandFacebook color="var(--blue-primary)" size={20} />
            </a>
            <a
              href="https://www.linkedin.com/company/future-fish-agrotech/"
              className="link"
              target="_blank"
            >
              <IconBrandLinkedin color="var(--blue-primary)" size={20} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCV-4gU0UHWozSuU5LD_mChw"
              className="link"
              target="_blank"
            >
              <IconBrandYoutubeFilled color="var(--blue-primary)" size={20} />
            </a>
            <a
              href="https://instagram.com/futurefishagro"
              className="link"
              target="_blank"
            >
              <IconBrandInstagram color="var(--blue-primary)" size={20} />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
