import { Container } from "@mui/material";
import { Text } from "@mantine/core";

import PeterImage from "../../../Assets/IMG/Farmers/Articles/Peter.jpg";
import AhmedImage from "../../../Assets/IMG/Farmers/Articles/Ahmed.jpg";
import SarahImage from "../../../Assets/IMG/Farmers/Articles/Sarah.jpg";

import "./styles.scss";
export default function FarmersArticles() {
  const articles = [
    {
      image: PeterImage,
      title: "Peter's success in Nigeria",
      subtitle: "Cutting Feed Costs with Precision",
      description:
        "Peter, a dedicated fish farmer in Nigeria, was determined to grow his business but faced a significant challenge — the high cost of feed. He struggled with balancing the right feeding amounts, often wasting feed or underfeeding his fish, which slowed their growth. With the support of Future Fish AgroTech’s automated feeding system, Peter now controls the feeding process with pinpoint accuracy. The system delivers the right quantity at the perfect time, reducing waste by 25%. Empowered by this technology, Peter not only slashed his costs but also boosted his fish yield by 30%. He now runs his farm more efficiently, giving him the confidence to expand.",
    },
    {
      image: SarahImage,
      title: "Sarah in Ghana",
      subtitle: "Running her farm from anywhere",
      description:
        "Sarah, a passionate tilapia farmer in Ghana, wanted to manage her growing farm without constantly traveling between her ponds. The manual monitoring was exhausting and costly. With Future Fish AgroTech’s remote pond monitoring system, Sarah gained full control of her farm, no matter where she was. The system sends real-time updates on water conditions and fish health directly to her phone, allowing her to make informed decisions from afar. With this newfound freedom, Sarah reduced her operational costs by 20%, empowered by the knowledge and control to run her farm her way. She now has the time and resources to scale her business, putting her on the path to greater success.",
    },
    {
      image: AhmedImage,
      title: "Ahmed's Transformation in Uganda",
      subtitle: "Saving Water, Growing Strong",
      description:
        "Ahmed, a fish farmer in Uganda, was paying a hefty price for water, especially during the dry season. Managing water levels was tricky, and inefficiency led to unnecessary waste. After integrating Future Fish AgroTech’s smart water regulation system, Ahmed took charge of his water usage, cutting it down by 30%. The technology empowered him to save water without compromising his fish’s health, creating a sustainable way to farm. Now, Ahmed not only enjoys lower water bills but has seen his production rise by 20%. With a stronger business and fewer overheads, Ahmed is confident that his farm will continue to thrive.",
    },
  ];
  return (
    <div className="articles-container">
      <Container>
        <Text className="section-header">Testimonials</Text>
        <div className="articles flex-col">
          {articles.map((article, index) => {
            const directions = ["down", "right", "up", "left"];
            const randomDirection =
              directions[Math.floor(Math.random() + directions.length)];
            return (
              <div
                key={article.title.concat(index.toString())}
                className={`article flex-row justify-between width-100 ${
                  index === 1 ? "article-reversed" : ""
                }`}
                data-aos={`fade-${randomDirection}`}
              >
                <img
                  src={article.image}
                  alt=""
                  className="image"
                  data-aos={`fade-${randomDirection}`}
                />
                <div
                  className="content flex-col"
                  data-aos={`fade-${randomDirection}`}
                >
                  <Text className="title">{article.title}</Text>
                  <Text className="dimmed">{article.subtitle}</Text>
                  <Text className="description">{article.description}</Text>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
