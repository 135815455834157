import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { NonPaginatedResponse } from "../../Lib/Responses";
import { BlogPost } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import "./styles.scss";
import { Skeleton } from "@mantine/core";
export default function ViewPost() {
  const { postID } = useParams();
  const { data: post, isLoading } = usePerformRequest<
    NonPaginatedResponse<BlogPost>
  >({
    method: "GET",
    url: `${Endpoints.GetSinglePost}/${postID}`,
  });
  return (
    <div className="view-post-container">
      <Container maxWidth="lg">
        {isLoading ? (
          <>
            <div className="flex-row align-center justify-between">
              <Skeleton height={150} circle mb="xl" />
              <Skeleton height={150} w="80%" radius="sm" />
            </div>

            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} radius="xl" />
            <Skeleton height={20} mt={9} width="70%" radius="xl" />
            <br />
          </>
        ) : (
          <div className="e-richtexteditor">
            <div className="e-rte-content ">
              <div className="e-content">
                <div className="post-preview-container">
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: post?.data?.content ?? "",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
